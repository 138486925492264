@import (reference) '@mc/wink/styles/tokens.less';

.formFeedback {
    padding: (@base-unit * 3);
    background-color: @brand-yam;
    display: grid;
    grid-template-columns: 24px auto;
    grid-template-areas: 'icon content';
    grid-column-gap: @base-unit * 3;
    align-items: center;

    .feedbackIcon {
        line-height: 0;
    }
    svg {
        fill-opacity: 1;
    }

    ul {
        list-style-type: initial;
        margin-left: @base-unit * 4;
    }

    a {
        color: @brand-peppercorn;
    }
}
