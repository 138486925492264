@import (reference) '@mc/wink/styles/tokens.less';

body {
    background-color: var(--brand-parsnip);
}

.pageContainer {
    background-color: var(--brand-coconut);
    min-height: calc(var(--base-unit) * 40);
    padding: var(--spacing-8);
    margin: 0 auto calc(var(--base-unit) * 25) auto;
    max-width: calc(var(--base-unit) * 175);
}

.innerContainer {
    margin: 0 auto;
    max-width: var(--readable-max-width);
}

.rootContainer {
    position: relative;
    min-height: 100vh;
}

.footerContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.footerBar {
    background: rgba(255, 255, 255, 0.6);
    padding: var(--spacing-3) var(--spacing-5);
}

.privacyLink {
    color: var(--brand-peppercorn);
    font-size: var(--font-size-small);
    text-decoration: none;
    &:hover {
        color: var(--brand-peppercorn);
        text-decoration: underline;
    }
}

.createdWithLink,
.createdWithLink:hover {
    font-size: 10px;
    color: var(--brand-peppercorn);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.createdWithLogo {
    width: 100px;
    margin-left: var(--spacing-2);
}

.footerBarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;
    height: 40px;
}

.successMessageContainer {
    padding: var(--spacing-8);
    margin: var(--spacing-6) 0;
}
