@import (reference) "css/less/global-variables/all.less";

.container {
    padding: @base-unit * 8;
    text-align: center;
    margin: 0 auto;
}

.illustration {
    max-height: (@base-unit * 40);
    max-width: (@base-unit * 40);
    height: auto;
    width: auto;
    margin: 0 auto @base-unit * 4;
}

.header {
    margin-bottom: @base-unit;
}
