@import (reference) "@mc/wink/styles/tokens.less";

.small {
    max-width: @base-unit * 45;
}

.medium {
    max-width: @base-unit * 90;
}

.large {
    max-width: 100%;
}
