@import (reference) '@mc/wink/styles/tokens.less';

.fieldWrapper {
    display: block;
}

.monthInput input,
.dayInput input {
    width: 80px;
}

.yearInput input {
    width: 100px;
}

.nonDefaultLabel {
    display: block;
    font-weight: @font-weight-bold;
    margin-bottom: @base-unit * 1.5;

    &.noMargin {
        margin-bottom: 0;
    }
}

.containerError {
    outline: solid 1px var(--input--error);
    outline-offset: @base-unit * 2;
}

.errorMessage {
    color: var(--text-error-color);
    margin-top: @base-unit * 2;
}
